html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

.App {
  text-align: center;
}
a {
  text-decoration: none;
  color: #C2AA2C;
}

.profileField .Mui-disabled {
  color: #51C46C !important;
}

.MuiFilledInput-input, .MuiOutlinedInput-root, .MuiInputBase-formControl {
  background-color: #fff !important;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined{
  background-color:  #51C46C;
  color: #ffffff !important;
  padding: 0 10px 5px 10px;
  margin-left: -5px;
  margin-top: 1px;
  border-radius: 2px;
  border-width: 2px;
  border-color: #ffff;

}
.MuiFormHelperText-root {
    background-color: rgba(255,255,255,.7);
    border-radius: 4px;
    padding-left: 10px;
}
.MuiOutlinedInput-notchedOutline > legend {
max-width: 0 !important;
width: 0  !important;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined > input {
  color: blue;
}
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.MuiInputBase-input.Mui-disabled {
   background-color: #daf5d8;
   color: #51C46C !important;
}
